"use strict";

import $ from 'jquery';
window.$ = window.jQuery = $;
//require('popper');
//require('./vendor/bootstrap.min.js');
require('bootstrap');
require('ajaxchimp');
require('jquery-nice-select');
require('magnific-popup');
require('parsleyjs');
require('slick-carousel');
import Inputmask from "inputmask";
import ymaps from 'ymaps';


$(document).ready(function(){
	var window_width 	 = $(window).width(),
	window_height 		 = window.innerHeight,
	header_height 		 = $(".default-header").height(),
	header_height_static = $(".site-header.static").outerHeight(),
	fitscreen 			 = window_height - header_height;


	$(".fullscreen").css("height", window_height - 100);
	$(".fitscreen").css("height", fitscreen);


     // -------   Active Mobile Menu    -----//

    $(".js-link-map").on('click', function(e) {
        e.preventDefault();
        $('.map-block').toggleClass('open');
    });

    $(".menu-bar").on('click', function(e){
        e.preventDefault();
        $("nav").toggleClass('hide');
    });

    $(".menu-close").on('click', function(e){
        e.preventDefault();
        $("nav").addClass('hide');
    });

    $('.js-link-popup-requisites').magnificPopup({
        type:'inline',
        midClick: true
    });

    $('.popup-image').magnificPopup({
        type: 'image'
    });

    $('.simple-ajax-popup').magnificPopup({
        type: 'ajax'
    });


    $('#mc_embed_signup').find('form').ajaxChimp();

    // -------   Scroll to div

    function goTo(element,speed){
        var href = element.attr('href');
        var target = $(href).offset().top;
        $("body, html").animate({scrollTop : target}, 1000);
    }


    $('.js-link-scroll').click(function(e){
        e.preventDefault();
        goTo($(this),1000);
    });

        $(".js-section-expand").click(function () {
                $('.obj-area').toggleClass('expand');
        });

    //Scroll to div

    $(".js-btn-down").click(function(e) {
        e.preventDefault();
        goToByScroll($(this).attr("id"));
    });

    $('.slider-license').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [

            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    });

    Inputmask("+7 (999) 999-99-99").mask('input.phone');

    $.ajaxSetup({
        headers: {
            'X-XSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            'X-Requested-With': 'XMLHttpRequest'
        }
    });

    $(document).on('submit', '.form-feedback form', function(e) {
        let $form = $(this);
        let $formContainer = $form.closest('.form-feedback');
        let errorContainer, successContainer, noticeContainer, k;
        e.preventDefault();

        if($formContainer.find('.alert-info').length > 0) {

        } else {
            $formContainer.prepend('<div class="alert alert-info">Отправка</div>');
        }
        noticeContainer = $formContainer.find('.alert-info');
        noticeContainer.show();
        if($formContainer.find('.alert-danger').length > 0) {
            $formContainer.find('.alert-danger').empty();
        } else {
            $formContainer.prepend('<div class="alert alert-danger"></div>');
        }
        errorContainer = $formContainer.find('.alert-danger');
        errorContainer.hide();
        if($formContainer.find('.alert-success').length > 0) {
            $formContainer.find('.alert-success').empty();
        } else {
            $formContainer.prepend('<div class="alert alert-success"></div>');
        }
        successContainer = $formContainer.find('.alert-success');
        successContainer.hide();

        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serialize(),
            dataType: 'json',
            success: function(response) {
                noticeContainer.hide();
                if (response.success) {
                    successContainer.show();
                    successContainer.html(response.message);
                    $form.find('input[type="text"], textarea').val('');
                } else {
                    errorContainer.show();
                    errorContainer.html('<ul></ul>');
                    for (k in response.errors) {
                        errorContainer.find('ul').append('<li>' + response.errors[k] + '</li>');
                    }
                }
            },
            error: function(data, textStatus, errorThrown) {
                noticeContainer.hide();
                errorContainer.show();
                errorContainer.html('<ul><li>Что-то пошло не так. Попробуйте перезагрузить страницу или повторить позднее.</li></ul>');
            }
        });
    });
});


//Yandex Map
//ymaps.ready(function () {
ymaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_RU').then(maps => {
    const myMap = new maps.Map("map", {
            center: [59.981771, 30.314637],
            zoom: 15
        }),


    // Custom Point

    myPlacemark = new maps.Placemark(myMap.getCenter(), {
        hintContent: 'Setl Tel - Оператор связи ООО «Управляющая компания «Петербургская Недвижимость»'
    }, {
        iconLayout: 'default#image',
        iconImageHref: '../images/logo-about.svg',
        iconImageSize: [70, 70],
        iconImageOffset: [-15, -48]
    });

    myMap.geoObjects
        .add(myPlacemark);
});

function printDiv() {
    var divToPrint = document.getElementById('DivIdToPrint');
    var newWin = window.open('', 'Print-Window');
    newWin.document.open();
    newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');
    newWin.document.close();
    setTimeout(function() {
        newWin.close();
    }, 10);
}
